import React, { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ControlledAccordion from '../components/Accordion/ControlledAccordion';
import GradientTagContainer from '../components/common/GradientTagContainer/GradientTagContainer';
import PaymentGateway from '../components/common/PaymentGateway/PaymentGateway';
import GetStarted from '../components/GetStarted';
import Layout from '../components/Layout';
import OtherProducts from '../components/OtherProducts/OtherProducts';
import SEO from '../components/SEO';
import Tabs from '../components/Tabs/Tabs';
import {
  apiBankingModelsData,
  bankingWorkCards,
  experiencePower,
  otherProducts,
} from '../content/api-banking';
import '../styles/api-banking.scss';

gsap.registerPlugin(ScrollTrigger);

function ApiBankingPage(rest) {
  useEffect(() => {
    const mm = gsap.matchMedia();
    mm.add('(min-width:768px)', () => {
      const mtl = gsap.timeline({
        paused: false,
        repeat: -1,
        yoyo: false,
        scrollTrigger: {
          trigger: '.bank-card-container',
          once: true,
        },
      });
      const duration = 1;
      const lineDuration = 2.5;
      const staggerCall = 0.2;
      const durationCall = 0.2;
      mtl
        .fromTo(
          '.bwc-image-1 svg path:nth-child(2)',
          {
            x: -10,
          },
          { x: 0, duration },
        )
        .fromTo(
          '.bwc-image-1 svg path:nth-child(1)',
          {
            x: 10,
          },
          { x: 0, duration },
          0,
        )
        .to(
          '.bwc-image-1 svg',
          {
            borderColor: '#6933D3',
            duration: 0.5,
            ease: 'Linear.easeNone',
          },
          0,
        )
        .to('.bwc-image-1 svg', {
          borderColor: 'transparent',
          duration: 0.2,
          delay: lineDuration,
          ease: 'Linear.easeNone',
        })
        .fromTo(
          '.bwc-image-2 svg path:nth-child(2)',
          {
            x: -10,
          },
          { x: 8, duration },
          'second',
        )
        .fromTo(
          '.bwc-image-2 svg path:nth-child(1)',
          {
            x: 10,
          },
          { x: -8, duration },
          'second',
        )
        .to(
          '.bwc-image-2 svg',
          {
            borderColor: '#6933D3',
            duration: 0.5,
            ease: 'Linear.easeNone',
          },
          'second',
        )
        .to('.bwc-image-2 svg', {
          borderColor: 'transparent',
          duration: 0.2,
          delay: lineDuration,
          ease: 'Linear.easeNone',
        })
        .to(
          ".bwc-image-3 svg path[fill='white']",
          {
            y: -5,
            stagger: {
              from: 'start',
              each: staggerCall,
            },
            duration: durationCall,
          },
          'third',
        )
        .to(".bwc-image-3 svg path[fill='white']", {
          y: 5,
          delay: -staggerCall * 2,
          stagger: {
            from: 'start',
            each: staggerCall,
          },
          duration: durationCall,
        })
        .to(".bwc-image-3 svg path[fill='white']", {
          y: 0,
          delay: -staggerCall * 2,
          stagger: {
            from: 'start',
            each: staggerCall,
          },
          duration: durationCall,
        })
        .to(
          '.bwc-image-3 svg',
          {
            borderColor: '#6933D3',
            duration: 0.5,
            ease: 'Linear.easeNone',
          },
          'third',
        )
        .to('.bwc-image-3 svg', {
          borderColor: 'transparent',
          duration: 0.2,
          delay: lineDuration,
          ease: 'Linear.easeNone',
        });
    });
    mm.add('(max-width:767px)', () => {
      const mtl = gsap.timeline({
        paused: false,
        repeat: -1,
        yoyo: false,
        scrollTrigger: {
          trigger: '.bank-card-container',
          once: true,
        },
      });

      const delay = 2.5;
      const duration = 1;
      const staggerCall = 0.2;
      const durationCall = 0.2;
      mtl
        .fromTo(
          '.bwc-image-1 svg path:nth-child(2)',
          {
            x: -10,
          },
          { x: 0, duration },
        )

        .fromTo(
          '.bwc-image-1 svg path:nth-child(1)',
          {
            x: 10,
          },
          { x: 0, duration },
          0,
        )
        .to(
          '.bwc-image-1 svg',
          {
            borderColor: '#6933D3',
            duration: 0.5,
            ease: 'Linear.easeNone',
          },
          0,
        )
        .to({}, { duration: delay })
        .to(
          '.bank-card-container',
          {
            xPercent: -100,
          },
          'slide1',
        )
        .to('.bwc-image-1 svg', {
          borderColor: 'transparent',
          duration: 0.2,
          ease: 'Linear.easeNone',
        })
        .to(
          '.bwc-image-2 svg',
          {
            borderColor: '#6933D3',
            duration: 0.5,
            ease: 'Linear.easeNone',
          },
          'second',
        )
        .to({}, { duration: 0.5 })
        .fromTo(
          '.bwc-image-2 svg path:nth-child(2)',
          {
            x: -10,
          },
          { x: 8, duration },
          'second',
        )
        .fromTo(
          '.bwc-image-2 svg path:nth-child(1)',
          {
            x: 10,
          },
          { x: -8, duration },
          'second',
        )
        .to({}, { duration: delay })
        .to(
          '.bank-card-container',
          {
            xPercent: -200,
          },
          'slide2',
        )
        .to('.bwc-image-2 svg', {
          borderColor: 'transparent',
          duration: 0.2,
          ease: 'Linear.easeNone',
        })
        .to('.bwc-image-3 svg', {
          borderColor: '#6933D3',
          duration: 0.5,
          ease: 'Linear.easeNone',
        })
        .to(".bwc-image-3 svg path[fill='white']", {
          y: -5,
          stagger: {
            from: 'start',
            each: staggerCall,
          },
          duration: durationCall,
        })
        .to(".bwc-image-3 svg path[fill='white']", {
          y: 5,
          delay: -staggerCall * 2,
          stagger: {
            from: 'start',
            each: staggerCall,
          },
          duration: durationCall,
        })
        .to(".bwc-image-3 svg path[fill='white']", {
          y: 0,
          delay: -staggerCall * 2,
          stagger: {
            from: 'start',
            each: staggerCall,
          },
          duration: durationCall,
        })
        .to({}, { duration: delay })
        .to('.bwc-image-3 svg', {
          borderColor: 'transparent',
          duration: 0.2,
          ease: 'Linear.easeNone',
        })
        .to('.bank-card-container', {
          xPercent: 0,
        });
    });
  }, []);
  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchants/login?source-action=API%20banking%20India&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=API%20banking%20India&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="API Banking for Fast-Growing Businesses in India."
        description="Understand API Banking, working, benefits and how to use API banking to step up your business banking"
        url="https://www.cashfree.com/api-banking-india/"
      />
      <section className="section-padding bg-cf-dark relative apibanking-hero text-white overflow-hidden">
        <div className="container position-relative gradient-bg">
          <div className="flex flex-wrap flex-col md:flex-row md:justify-between">
            <div className="relative z-[1] w-full lg:w-[40%] lg:self-center lg:mt-[-120px]">
              <div className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Api Banking
              </div>
              <span className="mb-[20px] text-xl md:text-2xl font-semibold max-w-[610px] font-heading block">
                <h1 className="inline">API Banking</h1> for fast-growing
                businesses in&nbsp;India
              </span>
              <div className="text-opacity-80 mb-[32px] max-w-[500px] text-[16px] leading-[24px] md:text-2.5md">
                Access 24*7 instant banking services. Connect your bank account
                with your ERP system or internal product using{' '}
                <h2 className="inline font-body">banking&nbsp;API</h2>s.
              </div>
              <a
                className="button button-green wide w-full md:w-auto"
                href="https://merchant.cashfree.com/merchants/signup?source-action=API%20banking%20India&action=Sign%20Up&button-id=StartNow_Hero">
                Create Account
                <span className="chevron" />
              </a>
            </div>
            <div className="w-[115%] ml-[-8%] lg:w-[55%] lg:mt-[-10%]  bg-video relative">
              <video
                autoPlay
                muted
                playsInline
                loop
                className="lg:max-w-[110%] inset-video aspect-square relative z-10 chrome-video my-[-10%] lg:my-0"
                width="1600"
                height="1600">
                <source
                  src="/img/api-banking/hero-video.webm"
                  type="video/webm"
                />
                <source
                  src="/img/api-banking/hero-video_H264.mp4"
                  type="video/mp4"
                />
              </video>
              <video
                autoPlay
                muted
                playsInline
                loop
                className="lg:max-w-[110%] inset-video hidden safari-video aspect-square relative z-10"
                width="1600"
                height="1600">
                <source
                  src="/img/api-banking/hero-video_H264.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-2 text-cf-hero">
        <div className="container">
          <div className="flex flex-wrap flex-col-reverse md:flex-row">
            <img
              className="w-full md:w-1/2"
              src="/img/api-banking/what-apibanking.svg"
              width="610"
              height="541"
              alt=""
            />
            <div className="w-full md:w-1/2 md:pl-[38px] md:self-center">
              <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 md:mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                API BANKING
              </p>
              <h2 className="text-shs md:text-shl font-semibold text-cf-hero max-w-[830px] mb-[12px] md:mb-8 font-heading">
                What is API Banking?
              </h2>
              <p className="max-w-[500px] text-2sm md:text-md">
                <h2 className="font-body inline">API Banking</h2> is an{' '}
                <b>online connection</b> which a third party can establish with
                a bank to get limited access to a bank&apos;s core system. Third
                parties can use the APIs to pull data and offer bank services
                like bank balance check, account creation etc for their
                customers.
              </p>

              <div className="mt-[21px] md:mt-[26px] mb-[24px] md:pb-0 text-2sm md:text-md">
                <b>Safer</b> Banking. <b>Efficient</b> Banking.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-2 bg-cf-light-grey">
        <div className="container api-banking-dropdown">
          <div className="text-shs md:text-shl font-semibold max-w-[620px] mb-6 md:mb-8 font-heading">
            API Banking Models
          </div>
          <Tabs
            data={apiBankingModelsData}
            dropDown
            dropDownFontSize="text-[16px]"
            timerAutoPlay
          />
        </div>
      </section>
      <section className="p-section-3">
        <div className="container text-center">
          <div className="text-shs md:text-shl font-semibold  mb-[12px] md:mb-[20px] font-heading text-center">
            How does API Banking&nbsp;work?
          </div>
          <p className="max-w-[660px] text-center mx-auto mb-0 text-2sm md:text-md">
            Banks (e.g. ICICI) allow limited and secured access to their core
            banking system for other third-party platforms, like Cashfree
            Payments, to access data and execute banking functions like
            transactions, balance queries, and checking
            account&nbsp;information.
          </p>
          <a
            className="button button-green w-auto wide mt-8 md:mt-[56px] hidden md:inline-block"
            href="https://merchant.cashfree.com/merchants/signup?source-action=API%20banking%20India&action=Sign%20Up&button-id=StartNow_HowdoesAPIBankingWork">
            Create Account <span className="chevron" />
          </a>
          <div className="mx-[-5%] md:mx-auto overflow-hidden pt-[32px] md:pt-0">
            <div className="flex flex-nowrap justify-between relative md:pt-[94px] mb-[32px] md:mb-14 max-w-[1042px] md:mx-auto bank-card-container">
              {bankingWorkCards.map((bwc) => (
                <div
                  className="w-full md:w-1/3 px-4 md:px-0 banking-work-card flex-shrink-0 md:flex-shrink"
                  key={bwc.id}>
                  <div className="relative max-w-[120px] mx-auto rounded-full box-shadow z-10">
                    <div className={`w-[120px] h-[120px] bwc-image-${bwc.id}`}>
                      {bwc.Image}
                    </div>
                  </div>
                  <div className="max-w-[286px] mx-auto">
                    <div className="mt-[8px] mb-[4px] md:mt-[28px] md:mb-[8px] text-[20px] leading-8 md:text-[24px] md:leading-9 font-semibold">
                      {bwc.title}
                    </div>
                    <p className="text-2.5sm md:text-md mb-0">{bwc.body}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <small className="italic text-tiny md:pt-3 block pr-1  font-normal text-[#180048] opacity-60">
            * The brand names have been used only for reference. Brand names &
            logos are property of the respective bank & institution.
          </small>
          <a
            className="button button-green w-full px-[24.5px] mt-[32px] mb-0 inline-block md:hidden"
            href="https://merchant.cashfree.com/merchants/signup?source-action=API%20banking%20India&action=Sign%20Up&button-id=StartNow_HowdoesAPIBankingWork">
            Create Account <span className="chevron" />
          </a>
        </div>
      </section>
      <section className="p-section-2 bg-cf-light-grey">
        <div className="container">
          <div className="text-shs md:text-shl font-semibold max-w-[620px] mb-[32px] md:mb-16 font-heading">
            Experience the power of API Banking with Cashfree Payments
          </div>
          <div className="flex flex-wrap items-stretch md:mx-[-19px]">
            {experiencePower.map(
              ({ id, description, heading, icon, learnMore }) => (
                <div
                  key={id}
                  className="w-full  md:w-1/3 md:px-[19px] mb-[40px] last:mb-[20px] last:md:mb-0 md:mb-0">
                  <img
                    className="w-[40px] h-[40px] mb-[16px] md:mb-[24px]"
                    src={icon}
                    width="40"
                    height="40"
                    alt=""
                  />
                  <h3 className="font-body font-semibold text-2md mb-[8px] md:mb-[12px]">
                    {heading}
                  </h3>
                  <p className="text-2.5sm md:text-2sm">{description}</p>
                  {learnMore && (
                    <a
                      className="button button-unstyled btn p-0 !text-cf-green group left-[83px] !right-auto mt-[12px] md:mt-[24px]"
                      href={learnMore}>
                      Learn More
                      <svg
                        className="!w-[6px] !h-[14px] ml-2.5 group-hover:translate-x-[3px] transition-transform duration-300"
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1 1L5 5.5L1 10"
                          stroke="#05C16E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                  )}
                </div>
              ),
            )}
          </div>
          <div className="flex md:justify-end md:pr-[12px]">
            <div className="flex flex-start xl:justify-between md:max-w-max flex-wrap banking-icon-list pb-7 md:pb-[10px] pr-5 lg:pr-0">
              <PaymentGateway
                backgroundWhite
                type="yesbank"
                disableName
                width="66px"
                height="66px"
                padding={2}
                className="self-center"
                imgWrapperClassName="max-w-[50px] max-h-[50px] md:max-w-max md:max-h-max"
              />
              <PaymentGateway
                type="indusindbankc"
                backgroundWhite
                disableName
                width="64px"
                height="64px"
                className="self-center"
                imgWrapperClassName="max-w-[50px] max-h-[50px] md:max-w-max md:max-h-max"
              />
              <PaymentGateway
                type="kotak"
                backgroundWhite
                disableName
                width="64px"
                height="64px"
                padding={12}
                className="self-center"
                imgWrapperClassName="max-w-[50px] max-h-[50px] md:max-w-max md:max-h-max"
              />
              <PaymentGateway
                type="icici"
                backgroundWhite
                disableName
                width="64px"
                height="64px"
                className="self-center"
                imgWrapperClassName="max-w-[50px] max-h-[50px] md:max-w-max md:max-h-max"
              />
              <PaymentGateway
                type="rbl"
                backgroundWhite
                grayscale
                disableName
                width="64px"
                height="64px"
                padding={18}
                className="self-center"
                imgWrapperClassName="max-w-[50px] max-h-[50px] md:max-w-max md:max-h-max rbl"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-3 bg-cf-hero new-age-bg">
        <div className="container text-center relative text-white">
          <p className="text-sm font-semibold text-white pl-5 mb-2 md:mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] inline-block mx-auto">
            CASHFREE PAYOUTS
          </p>
          <div className="text-shs md:text-shl font-semibold  mb-[12px] md:mb-[20px] font-heading text-center max-w-[620px] mx-auto">
            The new age{' '}
            <h2 className="font-heading inline">API Banking Platform</h2>{' '}
            in&nbsp;India
          </div>
          <p className="max-w-[620px] text-center mx-auto mb-8 md:mb-16 text-cf-cold-purple text-2sm md:text-md">
            With <span className="text-white">Cashfree Payouts</span>,
            businesses can manage their entire financial operations and make
            instant payouts via all available methods using our powerful,
            easy-to-use dashboard or robust API, that lets you do everything.
          </p>
          <ControlledAccordion />
          <GradientTagContainer
            heading={
              <>
                Savings account APIs are to transform retail banking
                in&nbsp;India.
              </>
            }
            subHeading="To know more, download the whitepaper."
            link="https://events.cashfree-mail.com/savings-account-api-whitepaper?utm_source=API%20banking%20India&utm_medium=FooterStrip&utm_campaign=savings-account-api-whitepaper"
            externalLink
            button="download"
          />
        </div>
      </section>
      <OtherProducts data={otherProducts} />
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=API%20banking%20India&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=API%20banking%20India&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}

export default ApiBankingPage;
