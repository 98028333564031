import React from 'react';

const Asset1 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none">
    <circle
      cx="60"
      cy="60"
      r="59.461"
      fill="white"
      stroke="white"
      strokeWidth="1.0779"
    />
    <mask
      id="mask0_1901_6341"
      //   style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="120"
      height="120">
      <circle cx="60" cy="60" r="60" fill="white" />
    </mask>
    <g mask="url(#mask0_1901_6341)">
      <path
        d="M75.5499 47.34H68.1199H63.8799C62.3899 47.34 61.1899 48.54 61.1899 50.03C61.1899 51.52 62.3999 52.72 63.8799 52.72H68.1199H75.5499V67.03H68.1199H63.8799C62.3899 67.03 61.1899 68.23 61.1899 69.72C61.1899 71.21 62.3999 72.41 63.8799 72.41H68.1199H75.5499V79.75H82.4599C93.4399 79.75 102.33 70.85 102.33 59.88C102.33 48.9 93.4299 40 82.4599 40H75.5499V47.34Z"
        fill="#DECDFF"
      />
      <path
        d="M43.8 72.41H51.23H55.47C56.96 72.41 58.16 71.2 58.16 69.72C58.16 68.24 56.95 67.03 55.47 67.03H51.23H43.8V52.72H51.23H55.47C56.96 52.72 58.16 51.52 58.16 50.03C58.16 48.54 56.95 47.34 55.47 47.34H51.23H43.8V40H36.87C25.89 40 17 48.9 17 59.87C17 70.84 25.9 79.74 36.87 79.74H43.8V72.41V72.41Z"
        fill="#9B65F9"
      />
    </g>
  </svg>
);
const Asset2 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none">
    <circle
      cx="60"
      cy="60"
      r="59.461"
      fill="white"
      stroke="white"
      strokeWidth="1.0779"
    />
    <mask
      id="mask0_1901_6306"
      //   style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="120"
      height="120">
      <circle cx="60" cy="60" r="60" fill="white" />
    </mask>
    <g mask="url(#mask0_1901_6306)">
      <path
        d="M50.8 52.73V47.35V40.01H43.87C32.89 40.01 24 48.91 24 59.88C24 70.86 32.9 79.75 43.87 79.75H50.8V72.41V67.03V52.73Z"
        fill="#9B65F9"
      />
      <path
        d="M76.8202 40H69.9102V47.34V52.72V67.03V72.41V79.75H76.8202C87.8002 79.75 96.6902 70.85 96.6902 59.88C96.6902 48.9 87.7902 40 76.8202 40Z"
        fill="#DECDFF"
      />
      <path
        d="M58.2401 52.73C56.7501 52.73 55.5501 51.53 55.5501 50.04C55.5501 48.55 56.7501 47.35 58.2401 47.35H50.8101V52.73H58.2401Z"
        fill="#9B65F9"
      />
      <path
        d="M55.5501 69.72C55.5501 68.23 56.7501 67.03 58.2401 67.03H50.8101V72.41H58.2401C56.7501 72.42 55.5501 71.21 55.5501 69.72Z"
        fill="#9B65F9"
      />
      <path
        d="M65.1602 50.03C65.1602 51.52 63.9502 52.72 62.4702 52.72H69.9002V47.34H62.4702C63.9602 47.34 65.1602 48.55 65.1602 50.03Z"
        fill="#DECDFF"
      />
      <path
        d="M55.5498 50.03C55.5498 51.52 56.7498 52.72 58.2398 52.72H62.4798C63.9698 52.72 65.1698 51.52 65.1698 50.03C65.1698 48.54 63.9598 47.34 62.4798 47.34H58.2398C56.7498 47.34 55.5498 48.55 55.5498 50.03Z"
        fill="#7D47E0"
      />
      <path
        d="M62.4702 67.03C63.9602 67.03 65.1602 68.23 65.1602 69.72C65.1602 71.21 63.9502 72.41 62.4702 72.41H69.9002V67.03H62.4702Z"
        fill="#DECDFF"
      />
      <path
        d="M65.16 69.72C65.16 68.23 63.95 67.03 62.47 67.03H58.23C56.74 67.03 55.54 68.23 55.54 69.72C55.54 71.21 56.74 72.41 58.23 72.41H62.47C63.96 72.42 65.16 71.21 65.16 69.72Z"
        fill="#7D47E0"
      />
    </g>
  </svg>
);
const Asset3 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none">
    <circle
      cx="60"
      cy="60"
      r="59.461"
      fill="white"
      stroke="white"
      strokeWidth="1.0779"
    />
    <mask
      id="mask0_1901_6322"
      //   style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="120"
      height="120">
      <circle cx="60" cy="60" r="60" fill="white" />
    </mask>
    <g mask="url(#mask0_1901_6322)">
      <path
        d="M60.35 52.73V47.35V40.01H43.87C32.89 40.01 24 48.91 24 59.88C24 70.86 32.9 79.75 43.87 79.75H60.35V72.41V67.03V52.73Z"
        fill="#9B65F9"
      />
      <path
        d="M76.8101 40H60.3501V47.34V52.72V67.03V72.41V79.75H76.8101C87.7901 79.75 96.6801 70.85 96.6801 59.88C96.6801 48.9 87.7801 40 76.8101 40Z"
        fill="#DECDFF"
      />
      <path
        d="M41.41 63.0801C43.1773 63.0801 44.61 61.6474 44.61 59.8801C44.61 58.1127 43.1773 56.6801 41.41 56.6801C39.6427 56.6801 38.21 58.1127 38.21 59.8801C38.21 61.6474 39.6427 63.0801 41.41 63.0801Z"
        fill="white"
      />
      <path
        d="M54.2498 63.0801C56.0171 63.0801 57.4498 61.6474 57.4498 59.8801C57.4498 58.1127 56.0171 56.6801 54.2498 56.6801C52.4825 56.6801 51.0498 58.1127 51.0498 59.8801C51.0498 61.6474 52.4825 63.0801 54.2498 63.0801Z"
        fill="white"
      />
      <path
        d="M67.0799 63.0801C68.8472 63.0801 70.2799 61.6474 70.2799 59.8801C70.2799 58.1127 68.8472 56.6801 67.0799 56.6801C65.3126 56.6801 63.8799 58.1127 63.8799 59.8801C63.8799 61.6474 65.3126 63.0801 67.0799 63.0801Z"
        fill="white"
      />
      <path
        d="M79.9197 63.0801C81.687 63.0801 83.1197 61.6474 83.1197 59.8801C83.1197 58.1127 81.687 56.6801 79.9197 56.6801C78.1524 56.6801 76.7197 58.1127 76.7197 59.8801C76.7197 61.6474 78.1524 63.0801 79.9197 63.0801Z"
        fill="white"
      />
    </g>
  </svg>
);
export default { Asset1, Asset2, Asset3 };
