import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import BulletPoint from '../common/BulletPoint/BulletPoint';
import './Accordion.scss';
import './ControlledAccordion.scss';
import debounce from '../../utils/debounce';

const WIDTH = 768;
const cashFreeWaysData = [
  {
    key: '1',
    heading: (
      <BulletPoint
        text={<>Easy to integrate. Developer-friendly&nbsp;APIs.</>}
        type="green-tick"
      />
    ),
    content: (
      <>
        Easily integrate with your website, app, and ERP and send money
        automatically. Use API or easy-to-use Dashboard. Move beyond file upload
        errors. Bulk payouts are easy&nbsp;now.
      </>
    ),
  },
  {
    key: '2',
    heading: (
      <BulletPoint
        text={<>Built for scale, works&nbsp;24x7</>}
        type="green-tick"
      />
    ),
    content: (
      <>
        Scale your business with Cashfree Payments&apos;s money transfer API.
        Process upto 100,000 payouts per day. Cashfree Banking APIs work 24x7.
        Money moves even when banks&nbsp;don&apos;t.
      </>
    ),
  },
  {
    key: '3',
    heading: <BulletPoint text="Multi-bank support." type="green-tick" />,
    content: (
      <>
        With the integration of multiple banks, Cashfree Payments provides the
        ability to reroute transactions to alternate banks if the originating
        bank server is not&nbsp;responsive.
      </>
    ),
  },
  {
    key: '4',
    heading: (
      <BulletPoint text="Intelligent reconciliation" type="green-tick" />
    ),
    content: (
      <>
        Achieve near 100% reconciliation within 24 hours. Transfers, failures,
        reversals and reconciliation - automate the manual reconciliation
        process across multiple transfer modes, destination banks and
        reporting&nbsp;formats.
      </>
    ),
  },
  {
    key: '5',
    heading: (
      <BulletPoint
        text={<>Frictionless verification. Verify before you&nbsp;pay.</>}
        type="green-tick"
      />
    ),
    content: (
      <>
        You can use Cashfree Payments Bank Account Verification feature to
        validate the accuracy of bank account number and account holder name
        before sending money in real-time. Avoid the grunt work and verify UPI
        IDs in bulk.
        <p className="pt-4">
          Automate{' '}
          <a
            className="underline text-cf-green"
            href="https://www.cashfree.com/gst-verification/?utm_source=website&utm_medium=announcement-strip&utm_campaign=gst-verification">
            GSTIN verification
          </a>{' '}
          for your vendors and supplies.
        </p>
      </>
    ),
  },
];
const oldWaysData = [
  {
    key: '1',
    heading: <BulletPoint text="Tricky integrations" type="red-cancel" />,
    content: (
      <>
        Bank APIs are often written with SOAP protocol that is complex to
        integrate and offer lesser flexibility around sending data. Your
        business would typically take 6 months or more to get&nbsp;started.
      </>
    ),
  },
  {
    key: '2',
    heading: (
      <BulletPoint
        text={<>Limited Transactions Per Second&nbsp;(TPS)</>}
        type="red-cancel"
      />
    ),
    content: (
      <>
        Bank servers can process only a certain number of transactions per
        second (TPS). If you are a scaling business, all such transactions will
        fail, if you try to process over the set&nbsp;limit.
      </>
    ),
  },
  {
    key: '3',
    heading: (
      <BulletPoint
        text={<>Dependence on a single bank&nbsp;account</>}
        type="red-cancel"
      />
    ),
    content: (
      <>
        Integration is time-consuming for a single account. When a specific
        bank’s server is down, there is no alternative for your business
        to&nbsp;transact.
      </>
    ),
  },
  {
    key: '4',
    heading: <BulletPoint text="Difficult reconciliation" type="red-cancel" />,
    content: (
      <>
        In case of any failed or reversed transactions, identifying and
        reconciling them requires effort. Different banks and different payment
        methods tend to have varying reporting formats and types of issues,
        further complicating the&nbsp;problem.
      </>
    ),
  },
  {
    key: '5',
    heading: (
      <BulletPoint text="Friction with verification" type="red-cancel" />
    ),
    content: (
      <>
        The ID Verification process causes too much friction. Customer approval
        can take minutes, hours, or even days in some cases, as customers must
        go through a manual and friction-heavy onboarding&nbsp;process.
      </>
    ),
  },
];
function ControlledAccordion({ data1, data2, alwaysOpen }) {
  const [activeTab1, setActiveTab1] = useState(
    data1.length ? data1[0]?.key : '',
  );
  const [activeTab2, setActiveTab2] = useState(
    data2.length ? data2[0]?.key : '',
  );
  const activeClass1 = (key) => `${(activeTab1 === key && 'active') || ''}`;
  const activeClass2 = (key) => `${(activeTab2 === key && 'active') || ''}`;
  const onClick = (tab, key) => {
    if (!window) return;
    if (window.innerWidth < WIDTH) {
      if (tab === 1) {
        if (alwaysOpen) {
          setActiveTab1(key);
        } else {
          setActiveTab1((prevKey) => (prevKey === key ? null : key));
        }
      } else if (alwaysOpen) {
        setActiveTab2(key);
      } else {
        setActiveTab2((prevKey) => (prevKey === key ? null : key));
      }
      return;
    }
    if (alwaysOpen) {
      setActiveTab1(key);
      setActiveTab2(key);
    } else {
      setActiveTab1((prevKey) => (prevKey === key ? null : key));
      setActiveTab2((prevKey) => (prevKey === key ? null : key));
    }
  };
  useEffect(() => {
    const resize = debounce(() => {
      if (!window) return;
      if (window.innerWidth > WIDTH && activeTab1 !== activeTab2) {
        setActiveTab1(data1.length ? data1[0]?.key : '');
        setActiveTab2(data2.length ? data2[0]?.key : '');
      }
    });
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [activeTab1, activeTab2]);
  return (
    <div className="flex relative flex-wrap max-w-full md:max-w-[83.3%] mx-auto flex-row items-stretch newage-accoridan text-black">
      <div className="w-full md:w-1/2  newage-accordion  md:pr-[19px]">
        <div className=" rounded-[4px] overflow-hidden h-full">
          <div className="bg-[#3D1F7A] text-white w-full pl-[32px] py-[20px] ">
            <div className="text-left text-2md font-medium text-[#DFD7F4]">
              The Old Way
            </div>
          </div>
          <div className="accordion-container default h-full">
            {data1.map((d) => (
              <div
                key={d.key}
                className={`tab text-left overflow-hidden w-full ${activeClass1(
                  d.key,
                )}`}>
                <button
                  className="button-unstyled w-full flex flex-row justify-between text-2.5sm md:text-md accordion-heading font-semibold font-body text-cf-hero"
                  type="button"
                  onClick={() => onClick(1, d.key)}
                  tabIndex={0}>
                  {d.heading}
                  <Icon
                    name="chevron-light"
                    width="11px"
                    className="self-center"
                    rotate={activeTab1 === d.key ? '180deg' : '0deg'}
                  />
                </button>
                <div
                  className={`accordion-content text-cf-hero ${activeClass1(
                    d.key,
                  )}`}>
                  {d.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 newage-accordion rounded-[4px] md:pl-[19px] mt-6 md:mt-0">
        <div className=" rounded-[4px] overflow-hidden">
          <div className="bg-cf-primary text-white w-full pl-[32px] py-[20px]">
            <div className="text-left text-2md font-medium">
              The New Cashfree Payments Way
            </div>
          </div>
          <div className="accordion-container default">
            {data2.map((d) => (
              <div
                key={d.key}
                className={`tab text-left overflow-hidden w-full ${activeClass2(
                  d.key,
                )}`}>
                <button
                  className="button-unstyled w-full flex flex-row justify-between text-2.5sm md:text-md accordion-heading font-semibold font-body text-cf-hero"
                  type="button"
                  onClick={() => onClick(2, d.key)}
                  tabIndex={0}>
                  {d.heading}
                  <Icon
                    name="chevron-light"
                    width="11px"
                    className="self-center"
                    rotate={activeTab2 === d.key ? '180deg' : '0deg'}
                  />
                </button>
                <div
                  className={`accordion-content text-cf-hero ${activeClass2(
                    d.key,
                  )}`}>
                  {d.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
ControlledAccordion.defaultProps = {
  data1: oldWaysData,
  data2: cashFreeWaysData,
  alwaysOpen: true,
};
ControlledAccordion.propTypes = {
  data1: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  data2: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  alwaysOpen: PropTypes.bool,
};

export default ControlledAccordion;
