import React from 'react';
import TabPanel from '../components/Tabs/TabPanel';
import Assets from '../components/SvgComponents/ApiBanking';

export const headingClass = 'mt-[32px] mb-2 md:mb-[24px]';
export const mobileVisibleHeading = false;
export const apiBankingModelsData = [
  {
    key: 0,
    heading: 'Open Banking',

    content: (
      <div className="mt-0 md:mt-12">
        <TabPanel
          img={{
            src: '/img/api-banking/open-banking.svg',
            width: '610',
            height: '370',
          }}
          heading="Open Banking"
          headingClass={headingClass}
          alignStart
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName="w-full"
          contentClassName="md:pl-[38px] mt-0"
          body={
            <>
              <div className="max-w-[500px]">
                Open banking refers to making customer data that was
                traditionally only with banks, easily available and accessible
                via the APIs to third parties, allowing easy
                credit&nbsp;decisioning,&nbsp;etc.
              </div>
              <div className="mt-[24px] max-w-[500px]">
                Thus, using{' '}
                <h2 className="inline font-body">Open Banking API</h2>s
                facilitate safe and secure access of customer data to third
                parties that are working in collaboration with&nbsp;banks.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green w-auto lg-cta mt-[32px] md:mt-[40px]"
              href="https://www.cashfree.com/blog/open-banking-api/">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'Banking as a Service (BaaS)',
    content: (
      <div className="mt-0 md:mt-12">
        <TabPanel
          img={{
            src: '/img/api-banking/baas.svg',
            width: '610',
            height: '366',
          }}
          heading="Banking as a Service (BaaS)"
          alignStart
          headingClass={headingClass}
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName="w-full"
          contentClassName="md:pl-[38px] mt-0"
          body={
            <>
              <div className="max-w-[500px]">Open Banking enables BaaS.</div>
              <div className="mt-[24px] max-w-[540px]">
                Banking as a service or BaaS allows non-banks to offer core
                financial services to their customers by integrating with banks
                via&nbsp;APIs.
              </div>
              <div className="mt-[24px] max-w-[540px]">
                This allows businesses to embed financial services on
                their&nbsp;platform.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green w-auto lg-cta mt-[32px] md:mt-[40px]"
              href="https://www.cashfree.com/banking-as-a-service/">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: 'Platform Banking',
    content: (
      <div className="mt-0 md:mt-12">
        <TabPanel
          img={{
            src: '/img/api-banking/platform-banking.svg',
            width: '610',
            height: '361',
          }}
          heading="Platform Banking"
          headingClass={headingClass}
          alignStart
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName="w-full"
          contentClassName="md:pl-[38px] mt-0"
          body={
            <>
              <div className="max-w-[500px]">
                Platform banking is quite the opposite of BaaS and
                Open&nbsp;Banking.
              </div>
              <div className="mt-[24px] max-w-[500px]">
                Unlike previous models, the bank owns the customers, and doesn’t
                share its infrastructure but integrates services from a fintech
                service&nbsp;provider.
              </div>
            </>
          }
        />
      </div>
    ),
  },
];
export const bankingWorkCards = [
  {
    id: 1,
    title: 'Open',
    image: 'work-asset-1.svg',
    Image: Assets.Asset1,
    body: (
      <>
        ICICI <b>opens</b> its core banking system for Cashfree Payments
      </>
    ),
  },
  {
    id: 2,
    title: 'Connect',
    image: 'work-asset-2.svg',
    Image: Assets.Asset1,
    body: (
      <>
        Cashfree Payments integrates the bank APIs to establish a{' '}
        <b>connection</b> with ICICI’s core banking system
      </>
    ),
  },
  {
    id: 3,
    title: 'Call',
    image: 'work-asset-3.svg',
    Image: Assets.Asset3,
    body: (
      <>
        Cashfree Payments makes a request (API <b>calls</b>) to fetch the
        required data from ICICI servers or execute functions
      </>
    ),
  },
];
export const experiencePower = [
  {
    id: 0,
    icon: '/img/icons/fund-sources.svg',
    heading: 'Payouts Direct: Fund Sources',
    description:
      'Add multiple fund sources like current bank account, loan account, corporate credit card, Paytm wallet. Add funds, define weightage,  initiate payouts, check balance, track transfer status in real time, and do more.',
    learnMore: null,
  },
  {
    id: 1,
    icon: '/img/icons/integrated-payouts-green.svg',
    heading: 'Integrated Payouts',
    description:
      'Initiate customer refunds, pay vendors and employees, disburse loans or pay winnings, and more with Payouts 24x7, even on a bank holiday.',
    learnMore: 'https://www.cashfree.com/payouts/',
  },
  {
    id: 2,
    icon: '/img/icons/accounts-issuance.svg',
    heading: 'Accounts issuance',
    description:
      'Create bank accounts for your users in minutes, with 100% paperless online KYC. Link multiple existing bank accounts and access accounts from a single platform.',
    learnMore: 'https://www.cashfree.com/banking-as-a-service/',
  },
];
export const otherProducts = [
  {
    id: 0,
    title: 'Payouts',
    body:
      'Make payouts to any bank account / UPI ID / card/ AmazonPay / Paytm instantly even on a bank holiday.',
    url: 'https://www.cashfree.com/payouts/',
    iconname: '/img/icons/payouts-green.svg',
  },
  {
    id: 1,
    title: 'Cashgram',
    body:
      'Payout links to send money without collecting receiver bank account details or UPI details.',
    url: 'https://www.cashfree.com/cashgram/',
    iconname: '/img/icons/cashgram.svg',
  },
  {
    id: 2,
    title: 'Verification Suite',
    body:
      'Verify and validate users before onboarding to ensure the successful transfer of funds to beneficiaries. Verify bank accounts, UPI VPAs, IFSCs, PANs, Aadhaars, and GSTINs instantly.',
    url: 'https://www.cashfree.com/bank-account-verification',
    iconname: '/img/icons/bank-account-verification.svg',
  },
  {
    id: 3,
    title: 'Payment Gateway',
    body: 'Accept domestic and international payments for your website or app.',
    url: 'https://www.cashfree.com/payment-gateway-india/',
    iconname: '/img/icons/payment-gateway-2.svg',
  },
];
